"use strict";

function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
// jQuery free

(function () {
  var EVENT_KVIK_AJAX_PAYLOAD = 'kvik_ajax_payload';
  var EVENT_KVIK_AJAX_SNIPPETS_UPDATED = 'kvik_ajax_snippets_update';
  var EVENT_KVIK_AJAX_SNIPPET_UPDATED = 'kvik_ajax_snippet_update';
  var CLASS_AJAX = 'ajax';
  var DATA_AJAX_INIT = 'data-ajax-init';
  var DATA_AJAX_HREF = 'data-ajax-href';
  var DATA_AJAX_SHOW_LOADER = 'data-ajax-show-loader';
  var DATA_AJAX_INCLUDE_DATETIME = 'data-ajax-include-datetime';
  var DATA_AJAX_SEND_ONENTER = 'data-ajax-send-onenter';
  var sendOnEnterElement = null;
  function isObject(x) {
    return _typeof(x) === 'object' && !Array.isArray(x) && x !== null;
  }
  function processPayload(payload) {
    if (typeof payload === 'string') {
      payload = {
        modal: payload
      };
    }
    if (!isObject(payload)) {
      return payload;
    }
    for (var key in payload) {
      if (!['snippets', 'flashMessages', 'state', 'modal', 'dataLayer', 'redirect'].includes(key)) {
        window.KMPayload[key] = payload[key];
      }
    }
    if (!processPayloadRedirect(payload)) {
      processPayloadFlashMessages(payload);
      processPayloadModal(payload);
      processPayloadSnippets(payload);
      processPayloadDataLayer(payload);
      document.dispatchEvent(new CustomEvent(EVENT_KVIK_AJAX_PAYLOAD, {
        detail: payload
      }));
    }
  }
  function initElement(element) {
    if (element.hasAttribute(DATA_AJAX_INIT)) {
      return;
    }
    element.setAttribute(DATA_AJAX_INIT, '1');
    var eventName = 'click';
    if (element.tagName === 'FORM') {
      eventName = 'submit';
    }
    element.addEventListener(eventName, function (event) {
      event.preventDefault();
      sendAjaxRequestFromElement(element);
    });
  }
  function sendAjaxRequestFromElement(element) {
    if (element.hasAttribute(DATA_AJAX_SHOW_LOADER)) {
      window.km_modal.showLoader();
    }
    var url = getUrlFromElement(element);
    if (!url) {
      console.warn('No URL for element', element);
      return;
    }
    if (element.getAttribute(DATA_AJAX_INCLUDE_DATETIME)) {
      url += '&datetime=' + new Date().toISOString();
    }
    var config = {
      method: 'GET',
      mode: 'same-origin',
      // send cookies _nss for isSameSite()
      headers: {
        'X-Requested-With': 'XMLHttpRequest' // To force Nette isAjax() to be true.
      }
    };
    if (element.tagName === 'FORM') {
      config.method = element.method.toUpperCase();
      config.body = getFormElementWwwUrlEncoded(element);
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
      config.cache = 'no-store';
    }
    fetch(url, config).then(function (response) {
      if (response.headers.get('Content-Type').includes('json')) {
        return response.json();
      }
      return response.text();
    }).then(function (payload) {
      return processPayload(payload);
    })["catch"](function (error) {
      console.error(error);
    });
  }
  function sendRequestFromElement(element) {
    if (element.classList.contains(CLASS_AJAX)) {
      sendAjaxRequestFromElement(element);
    } else if (element.tagName === 'FORM') {
      window.km_modal.showLoader();
      element.submit();
    } else if (url = getUrlFromElement(element)) {
      window.km_modal.showLoader();
      location.href = url;
    } else {
      console.warn('No request from element.', element);
    }
  }
  function getUrlFromElement(element) {
    if (element.hasAttribute(DATA_AJAX_HREF)) {
      return element.getAttribute(DATA_AJAX_HREF);
    }
    if (element.tagName === 'FORM') {
      return element.getAttribute('action');
    }
    return element.getAttribute('href');
  }

  /**
   * @see https://ultimatecourses.com/blog/transform-formdata-into-query-string
   */
  function getFormElementWwwUrlEncoded(formElement) {
    var formData = new FormData(formElement);
    var data = _toConsumableArray(formData.entries());
    var wwwUrlEncoded = data.map(function (x) {
      return "".concat(encodeURIComponent(x[0]), "=").concat(encodeURIComponent(x[1]));
    }).join('&');
    console.debug(wwwUrlEncoded);
    return wwwUrlEncoded;
  }
  function processPayloadDataLayer(payload) {
    if (payload.dataLayer && Array.isArray(payload.dataLayer)) {
      for (var i = 0; i < payload.dataLayer.length; i++) {
        var message = payload.dataLayer[i];
        if (Array.isArray(message)) {
          window.gtag.apply(window, message);
        } else {
          window.dataLayer.push(message);
        }
      }
    }
  }
  function processPayloadRedirect(payload) {
    if (payload.redirect) {
      window.location.href = payload.redirect;
      return true;
    }
    return false;
  }
  function processPayloadFlashMessages(payload) {
    if (payload.flashMessages && payload.flashMessages.length) {
      var messages = [];
      for (var i = 0; i < payload.flashMessages.length; i++) {
        messages.push(payload.flashMessages[i].message);
      }
      window.km_modal.showModal(messages.join('<br>'));
    }
  }
  function processPayloadModal(payload) {
    if (payload.modal) {
      window.km_modal.showModal(payload.modal);
    }
  }
  function processPayloadSnippets(payload) {
    if (payload.snippets) {
      for (var id in payload.snippets) {
        var element = document.getElementById(id);
        element.innerHTML = payload.snippets[id];
        document.dispatchEvent(new CustomEvent(EVENT_KVIK_AJAX_SNIPPET_UPDATED, {
          detail: {
            element: element,
            snippetId: id
          }
        }));
      }
      initInDOM();
      document.dispatchEvent(new CustomEvent(EVENT_KVIK_AJAX_SNIPPETS_UPDATED));
    }
  }
  function initInDOM() {
    var kmAjaxElements = document.body.getElementsByClassName(CLASS_AJAX);
    for (var i = 0; i < kmAjaxElements.length; i++) {
      initElement(kmAjaxElements[i]);
    }
    sendOnEnterElement = document.querySelector("[".concat(DATA_AJAX_SEND_ONENTER, "]"));
  }
  onDOMReady(function () {
    if (window.KMPayload) {
      processPayload(window.KMPayload);
    }
    initInDOM();
    document.addEventListener(window.km_modal.EVENT_KM_MODAL_SHOW_MODAL, function (event) {
      initInDOM();
    });
  });
  document.addEventListener('keyup', function (event) {
    if (event.key === 'Enter' && sendOnEnterElement) {
      sendRequestFromElement(sendOnEnterElement);
    }
  });
  window.kvik_ajax = {
    processPayload: processPayload,
    initElement: initElement,
    sendAjaxRequestFromElement: sendAjaxRequestFromElement,
    sendRequestFromElement: sendRequestFromElement,
    EVENT_KVIK_AJAX_PAYLOAD: EVENT_KVIK_AJAX_PAYLOAD,
    EVENT_KVIK_AJAX_SNIPPETS_UPDATED: EVENT_KVIK_AJAX_SNIPPETS_UPDATED,
    EVENT_KVIK_AJAX_SNIPPET_UPDATED: EVENT_KVIK_AJAX_SNIPPET_UPDATED,
    DATA_AJAX_SHOW_LOADER: DATA_AJAX_SHOW_LOADER
  };
})();