"use strict";

// uses jQuery
(function () {
  /**
   * @namespace DeliverySetupLink
   * @property {number}  deliveryId           DeliverySetupDelivery.delivery_setup_delivery_id
   * @property {number}  priceAmount          DeliverySetupLink.price_amount
   * @property {string}  priceFormated        E.g.: "123.50 Kč"
   * @property {string}  priceFormatedAsFrom  E.g.: "Od 123.50 Kč"
   */

  var SELECTOR_DELIVERY_PAYMENT_FORM = '#kvikymart-scdpa-delivery-payment-form';
  var SELECTOR_DELIVERY_ITEM = '.kvikymart-scdpa-delivery-item';
  var SELECTOR_PAYMENT_ITEM = '.kvikymart-scdpa-payment-item';
  var SELECTOR_DELIVERY_ITEM_CHECKBOX = 'input[name=deliverySetupDeliveryId]';
  var SELECTOR_PAYMENT_ITEM_CHECKBOX = 'input[name=deliverySetupPaymentId]';
  var SELECTOR_STORES_WRAPPER = '.kvikymart-scdpa-stores-selection';
  var SELECTOR_STORE_INPUTS = '.kvikymart-scdpa__storeInput';
  var SELECTOR_PRICE = '.kvikymart-scdpa-price';
  var SELECTOR_SUBMIT_BUTTON_WRAPPER = '#kvikymart-scdpa-submit';
  var SELECTOR_AJAX_LOADER_WRAPPER = '#kvikymart-scdpa-ajax-loader';
  var SELECTOR_ADDRESS_FORM_WRAPPER = '#kvikymart-scdpa-address';
  var CLASS_MODIFIER_PRICE_FREE = 'kvikymart-scdpa-price-free';
  var VISIBLE = 'visible';
  var SELECTED = 'selected';
  var CHECKED = 'checked';
  var DISABLED = 'disabled';
  var PAYMENT_ITEM_EXCLUDED = 'kvikymart-scdpa-payment-item--excluded';
  var LOCKED = 'locked';
  var DATA_JS_INITIALIZED = 'data-js-initialized';
  var DATA_LINKS = 'data-kvikymart-links';
  var DATA_DEFAULT_PRICE_IS_FREE = 'data-default-price-is-free';
  var DATA_DEFAULT_PRICE_FORMATED = 'data-default-price-formated';
  var DATA_IS_FREE_PAYMENT = 'data-kvikymart-is-free-payment';
  var DATA_WORD_FREE = 'data-word-free';
  var DATA_REQUIRED_MESSAGE = 'data-required-message';

  /** @type {jQuery} */
  var $deliveryPaymentForm;

  /** @type {jQuery} */
  var $deliveryItemCheckboxes;

  /** @type {jQuery} */
  var $paymentItemCheckboxes;

  /** @type {jQuery} */
  var $storeWrappers;

  /** @type {jQuery} */
  var $storeInputs;

  /** @type {jQuery} */
  var $addressFormWrapper;

  /** @type {jQuery} */
  var $ajaxLoaderWrapper;

  /** @type {string} */
  var deliveryPaymentFormStateHash = '';
  var init = function init() {
    $deliveryPaymentForm = $(SELECTOR_DELIVERY_PAYMENT_FORM);
    if (!$deliveryPaymentForm.length || dataAttrToBoolean($deliveryPaymentForm.attr(DATA_JS_INITIALIZED)) === true) {
      return;
    }
    $deliveryPaymentForm.attr(DATA_JS_INITIALIZED, 'true');
    $deliveryItemCheckboxes = $(SELECTOR_DELIVERY_ITEM_CHECKBOX, $deliveryPaymentForm);
    $paymentItemCheckboxes = $(SELECTOR_PAYMENT_ITEM_CHECKBOX, $deliveryPaymentForm);
    $storeWrappers = $(SELECTOR_STORES_WRAPPER, $deliveryPaymentForm);
    $storeInputs = $(SELECTOR_STORE_INPUTS, $storeWrappers);
    $addressFormWrapper = $(SELECTOR_ADDRESS_FORM_WRAPPER);
    $ajaxLoaderWrapper = $(SELECTOR_AJAX_LOADER_WRAPPER);
    initStoresSelect2();
    displayPaymentItemsDefaultPrices();
    $deliveryItemCheckboxes.change(deliveryItemCheckboxChangeEventHandler);
    $paymentItemCheckboxes.change(paymentItemCheckboxChangeEventHandler);
    $storeInputs.change(storeInputChangeEventHandler);
    $storeWrappers.hide();

    // spuštění js change eventu pro delivery checkbox zatržený z backendu
    $deliveryItemCheckboxes.filter(':' + CHECKED).triggerHandler('change', [{
      isPageLoad: true
    }]);
    dispatchDeliveryMethodChangeEvent();
    document.addEventListener('km_storeInputChanged', function (event) {
      deliveryPaymentStoreInputsChangeHandler();
    });
    initAjaxLoading();
  };
  var initStoresSelect2 = function initStoresSelect2() {
    var $storesSelect2 = $storeInputs.filter('select');
    $storesSelect2.each(function (index, element) {
      var $select2 = $(element);
      var options = element.getElementsByTagName('option');
      var select2Config = {
        width: null,
        placeholder: $select2.attr('placeholder'),
        minimumResultsForSearch: 10
      };
      if (options.length === 1) {
        select2Config.minimumInputLength = 2;
        select2Config.language = {
          inputTooShort: function inputTooShort(args) {
            // args.minimum is the minimum required length
            // args.input is the user-typed text
            return $select2.attr('data-input-too-short-message');
          },
          inputTooLong: function inputTooLong(args) {
            // args.maximum is the maximum allowed length
            // args.input is the user-typed text
            return $select2.attr('data-input-too-long-message');
          },
          errorLoading: function errorLoading() {
            return $select2.attr('data-error-loading-message');
          },
          loadingMore: function loadingMore() {
            return $select2.attr('data-loading-more-message');
          },
          noResults: function noResults() {
            return $select2.attr('data-no-results-message');
          },
          searching: function searching() {
            return $select2.attr('data-searching-message');
          },
          maximumSelected: function maximumSelected(args) {
            // args.maximum is the maximum number of items the user may select
            return $select2.attr('data-maximum-selected-message');
          }
        };
        select2Config.ajax = {
          url: '/api/store/store-entry',
          dataType: 'json',
          delay: 250,
          type: 'GET',
          data: function data(params) {
            return {
              countryCode: $select2.attr('data-country-code'),
              deliverySetupDeliveryId: $select2.attr('data-delivery-setup-delivery-id'),
              term: params.term
            };
          },
          processResults: function processResults(data) {
            return {
              results: $.map(data, function (item) {
                return {
                  text: item.label,
                  id: item.externalId
                };
              })
            };
          },
          cache: false
        };
      }
      $select2.select2(select2Config);
      $select2.focus(function () {
        $select2.select2('open');
      });
    });
  };
  var displayPaymentItemsDefaultPrices = function displayPaymentItemsDefaultPrices() {
    $paymentItemCheckboxes.each(function (index, element) {
      var $paymentItemCheckbox = $(element);
      var $priceElement = $(SELECTOR_PRICE, $paymentItemCheckbox.closest(SELECTOR_PAYMENT_ITEM));
      setupPaymentItemDefaultPriceAttributes($paymentItemCheckbox);
      $priceElement.html($paymentItemCheckbox.attr(DATA_DEFAULT_PRICE_FORMATED));
      if (dataAttrToBoolean($paymentItemCheckbox.attr(DATA_DEFAULT_PRICE_IS_FREE)) === true) {
        $priceElement.addClass(CLASS_MODIFIER_PRICE_FREE);
      } else {
        $priceElement.removeClass(CLASS_MODIFIER_PRICE_FREE);
      }
    });
  };
  var setupPaymentItemDefaultPriceAttributes = function setupPaymentItemDefaultPriceAttributes($paymentItemCheckbox) {
    if ($paymentItemCheckbox.attr(DATA_DEFAULT_PRICE_IS_FREE)) {
      return;
    }

    /** @type {DeliverySetupLink} */
    var selectedLink;

    /** @type {DeliverySetupLink[]} */
    var links = $.parseJSON($paymentItemCheckbox.attr(DATA_LINKS));
    var lowestPriceAmount = Number.MAX_SAFE_INTEGER;
    var differences = {};
    if (links) {
      for (var i = 0; i < links.length; ++i) {
        var link = links[i];
        if ($deliveryItemCheckboxes.filter('[value="' + link.deliveryId + '"]').length === 0) {
          continue;
        }
        differences[link.priceAmount] = link.priceAmount;
        if (link.priceAmount < lowestPriceAmount) {
          lowestPriceAmount = link.priceAmount;
          selectedLink = link;
        }
      }
    }
    var differencesCount = Object.keys(differences).length;
    var defaultPriceFormated, defaultPriceIsFree;
    if (!selectedLink) {
      defaultPriceFormated = '';
      defaultPriceIsFree = false;
    } else if (lowestPriceAmount === 0) {
      defaultPriceFormated = differencesCount <= 1 ? $deliveryPaymentForm.attr(DATA_WORD_FREE) : selectedLink.priceFormatedAsFrom;
      defaultPriceIsFree = true;
    } else {
      defaultPriceFormated = differencesCount <= 1 ? selectedLink.priceFormated : selectedLink.priceFormatedAsFrom;
      defaultPriceIsFree = false;
    }
    $paymentItemCheckbox.attr(DATA_DEFAULT_PRICE_FORMATED, defaultPriceFormated);
    $paymentItemCheckbox.attr(DATA_DEFAULT_PRICE_IS_FREE, booleanToDataAttr(defaultPriceIsFree));
  };
  var changeSelectedClassesOnPaymentItems = function changeSelectedClassesOnPaymentItems() {
    $paymentItemCheckboxes.each(function (index, element) {
      var $checkbox = $(element);
      var $deliveryItem = $checkbox.closest(SELECTOR_PAYMENT_ITEM);
      if ($checkbox.prop(CHECKED) === true) {
        $deliveryItem.addClass(SELECTED);
      } else {
        $deliveryItem.removeClass(SELECTED);
      }
    });
  };
  var paymentItemCheckboxChangeEventHandler = function paymentItemCheckboxChangeEventHandler(event) {
    var $checkbox = $(event.currentTarget);
    $paymentItemCheckboxes.not($checkbox).prop(CHECKED, false);
    changeSelectedClassesOnPaymentItems();
  };
  var deliveryItemCheckboxChangeEventHandler = function deliveryItemCheckboxChangeEventHandler(event, extraParams) {
    var $deliveryItemCheckbox = $(event.currentTarget);
    $deliveryItemCheckboxes.closest(SELECTOR_DELIVERY_ITEM).removeClass(SELECTED);
    displayPaymentItemsDefaultPrices();
    $storeWrappers.hide();
    var $storesSelectionWrapper = $(SELECTOR_STORES_WRAPPER, $deliveryItemCheckbox.closest(SELECTOR_DELIVERY_ITEM));
    if ($deliveryItemCheckbox.prop(CHECKED) === true) {
      var $deliveryItem = $deliveryItemCheckbox.closest(SELECTOR_DELIVERY_ITEM);
      var $availablePaymentItemCheckboxes = [];
      $deliveryItem.addClass(SELECTED);
      $deliveryItemCheckboxes.not($deliveryItemCheckbox).prop(CHECKED, false);
      $storesSelectionWrapper.show();
      $paymentItemCheckboxes.each(function (index, element) {
        var $paymentItemCheckbox = $(element);
        var $paymentItem = $paymentItemCheckbox.closest(SELECTOR_PAYMENT_ITEM);
        var $price = $(SELECTOR_PRICE, $paymentItem);

        /** @type {DeliverySetupLink|undefined} */
        var deliverySetupLink = findDeliverySetupLink($deliveryItemCheckbox, $paymentItemCheckbox);
        //$price.html('');

        if (deliverySetupLink && !$paymentItem.hasClass(PAYMENT_ITEM_EXCLUDED)) {
          $availablePaymentItemCheckboxes.push($paymentItemCheckbox);
          $paymentItemCheckbox.prop(DISABLED, false);
          $paymentItem.removeClass(DISABLED);
          if (dataAttrToBoolean($deliveryItemCheckbox.attr(DATA_IS_FREE_PAYMENT)) === true || deliverySetupLink.priceAmount === 0) {
            $price.html($deliveryPaymentForm.attr(DATA_WORD_FREE));
            $price.addClass(CLASS_MODIFIER_PRICE_FREE);
          } else {
            $price.html(deliverySetupLink.priceFormated);
            $price.removeClass(CLASS_MODIFIER_PRICE_FREE);
          }
        } else {
          disablePaymentItem($paymentItemCheckbox);
        }
      });
      if ($availablePaymentItemCheckboxes.length === 1) {
        $availablePaymentItemCheckboxes[0].prop(CHECKED, true);
      }
      if (!(extraParams && extraParams.isPageLoad)) {
        var $currentStoreInput = $(SELECTOR_STORE_INPUTS, $deliveryItem);
        if (!$currentStoreInput.val()) {
          $currentStoreInput.focus();
        }
      }
    } else {
      $paymentItemCheckboxes.each(function (index, element) {
        var $paymentItemCheckbox = $(element);
        enablePaymentItem($paymentItemCheckbox);
      });
      displayPaymentItemsDefaultPrices();
    }
    changeSelectedClassesOnPaymentItems();
    dispatchDeliveryMethodChangeEvent();
  };

  /**
   * @returns {DeliverySetupLink|null}
   */
  var findDeliverySetupLink = function findDeliverySetupLink($deliveryItemCheckbox, $paymentItemCheckbox) {
    /** @type {DeliverySetupLink[]} */
    var links = $.parseJSON($paymentItemCheckbox.attr(DATA_LINKS));
    if (links) {
      for (var i = 0; i < links.length; ++i) {
        if (links[i].deliveryId === parseInt($deliveryItemCheckbox.val())) {
          return links[i];
        }
      }
    }
    return null;
  };
  var storeInputChangeEventHandler = function storeInputChangeEventHandler(event) {
    var $input = $(event.currentTarget);
    var $item = $input.closest(SELECTOR_DELIVERY_ITEM);
    var $checkbox = $(SELECTOR_DELIVERY_ITEM_CHECKBOX, $item);
    if (!$checkbox.prop(CHECKED)) {
      $checkbox.prop(CHECKED, true).triggerHandler('change');
    }
  };
  var disablePaymentItem = function disablePaymentItem($paymentItemCheckbox) {
    $paymentItemCheckbox.prop(CHECKED, false).prop(DISABLED, true);
    var $paymentItem = $paymentItemCheckbox.closest(SELECTOR_PAYMENT_ITEM);
    $paymentItem.addClass(DISABLED);
    $paymentItem.removeClass(SELECTED);
  };
  var enablePaymentItem = function enablePaymentItem($paymentItemCheckbox) {
    var $paymentItem = $paymentItemCheckbox.closest(SELECTOR_PAYMENT_ITEM);
    $paymentItemCheckbox.prop(DISABLED, false);
    $paymentItem.removeClass(DISABLED);
  };
  var setDeliveryPaymentItemsLock = function setDeliveryPaymentItemsLock(shouldBeLocked) {
    $paymentItemCheckboxes.each(function (index, element) {
      var $paymentItemCheckbox = $(element);
      var $paymentItem = $paymentItemCheckbox.closest(SELECTOR_PAYMENT_ITEM);
      if (shouldBeLocked) {
        $paymentItem.addClass(LOCKED);
      } else {
        $paymentItem.removeClass(LOCKED);
      }
    });
    $deliveryItemCheckboxes.each(function (index, element) {
      var $deliveryItemCheckbox = $(element);
      var $deliveryItem = $deliveryItemCheckbox.closest(SELECTOR_DELIVERY_ITEM);
      if (shouldBeLocked) {
        $deliveryItem.addClass(LOCKED);
      } else {
        $deliveryItem.removeClass(LOCKED);
      }
    });
  };
  var initAjaxLoading = function initAjaxLoading() {
    $(SELECTOR_SUBMIT_BUTTON_WRAPPER, $deliveryPaymentForm).hide();
    ajaxLoaderHide();
    var $allDeliveryPaymentStoreInputs = $deliveryItemCheckboxes.add($paymentItemCheckboxes).add($storeInputs);
    $allDeliveryPaymentStoreInputs.change(deliveryPaymentStoreInputsChangeHandler);

    // setInterval(() => {
    //     deliveryPaymentStoreInputsChangeHandler();
    // }, 1000);
  };
  var deliveryPaymentStoreInputsChangeHandler = function deliveryPaymentStoreInputsChangeHandler() {
    var newDeliveryPaymentFormStateHash = $deliveryPaymentForm.serialize();
    if (newDeliveryPaymentFormStateHash === deliveryPaymentFormStateHash) {
      return;
    }
    deliveryPaymentFormStateHash = newDeliveryPaymentFormStateHash;
    $deliveryPaymentForm.attr('data-valid', 0);
    //$addressFormWrapper.hide();

    ajaxLoaderShow();
    setDeliveryPaymentItemsLock(true);
    try {
      document.dispatchEvent(new Event('KMDeliveryPaymentAddressControl.addressFormLoad'));
    } catch (e) {}
    $.post($deliveryPaymentForm.attr('action'), $deliveryPaymentForm.serialize(), function (payload, textStatus, jqXHR) {
      kvik_ajax.processPayload(payload);
      ajaxLoaderHide();
      setDeliveryPaymentItemsLock(false);
      var $addressForm = $('form', $addressFormWrapper);
      //$addressFormWrapper.show();

      if ($addressForm.length) {
        Nette.initForm($addressForm.get(0));
        KMSelect2Input.makeAliveByClassIn($addressForm.get(0));
        $deliveryPaymentForm.attr('data-valid', 1);
        dispatchAddressFormLoadedEvent();
      } else {
        dispatchAddressFormHiddenEvent();
        $deliveryPaymentForm.attr('data-valid', 0);
      }
    });
  };
  var ajaxLoaderHide = function ajaxLoaderHide() {
    $ajaxLoaderWrapper.removeClass(VISIBLE);
    $ajaxLoaderWrapper.html('');
  };
  var ajaxLoaderShow = function ajaxLoaderShow() {
    $ajaxLoaderWrapper.addClass(VISIBLE);
    $ajaxLoaderWrapper.html(km_spinner.html);
  };
  var dispatchAddressFormHiddenEvent = function dispatchAddressFormHiddenEvent() {
    try {
      document.dispatchEvent(new Event('KMDeliveryPaymentAddressControl.addressFormHidden'));
    } catch (e) {}
  };
  var dispatchAddressFormLoadedEvent = function dispatchAddressFormLoadedEvent() {
    try {
      document.dispatchEvent(new Event('KMDeliveryPaymentAddressControl.addressFormLoaded'));
    } catch (e) {}
  };
  var dispatchDeliveryMethodChangeEvent = function dispatchDeliveryMethodChangeEvent() {
    if (!$deliveryItemCheckboxes) {
      return;
    }
    var $checkbox = $deliveryItemCheckboxes.filter(':' + CHECKED);
    if ($checkbox.length) {
      var dataObject = $checkbox.data();
      delete dataObject.netteRules;
      dataObject.checked = $checkbox.prop(CHECKED) === true;
      try {
        if (window.CustomEvent) {
          document.dispatchEvent(new CustomEvent('KMDeliveryPaymentAddressControl.deliveryMethodChange', {
            detail: dataObject
          }));
        }
      } catch (e) {}
    }
  };
  var booleanToDataAttr = function booleanToDataAttr(_boolean) {
    return _boolean ? 'true' : 'false';
  };
  var dataAttrToBoolean = function dataAttrToBoolean(attr) {
    return "".concat(attr).toLowerCase() === 'true' || "".concat(attr) === '1';
  };
  $(document).ready(function () {
    init();
  });
  window.KMDeliveryPaymentAddressControl = {
    init: init,
    dispatchDeliveryMethodChangeEvent: dispatchDeliveryMethodChangeEvent
  };
})();