"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var TREE_NAVIGATION_CLASS_NAME = 'js-treeNavigation';
var LOADER_CLASS_NAME = 'js-treeNavigation__ajaxLoader';
var DATA_INIT = 'data-init';
jQuery(document).ready(function () {
  var treeNavigations = document.getElementsByClassName(TREE_NAVIGATION_CLASS_NAME);
  for (var i = 0; i < treeNavigations.length; i++) {
    var treeNavigation = treeNavigations[i];
    //console.log(treeNavigation);
    initialiseTreeNavigation(treeNavigation);
  }
});

// Init the tree-navigation immediately if this script is already loaded by browser.
document.addEventListener('kvik_tree_navigation_dom_ready', function (event) {
  //console.log('kvik_tree_navigation_dom_ready', event.detail, document.getElementById(event.detail));
  initialiseTreeNavigation(document.getElementById(event.detail));
});
var initialiseTreeNavigation = function initialiseTreeNavigation(treeNavigation) {
  if (treeNavigation.hasAttribute(DATA_INIT)) {
    return;
  }

  //console.log(treeNavigation);
  var classBlock = treeNavigation.getAttribute('data-class-block');
  var classModifiers = treeNavigation.getAttribute('data-class-modifiers').split(' ');
  var openerClassNameSelector = createSelectorClassName(classBlock, 'openerTemplate');
  var openerTemplate = treeNavigation.getElementsByClassName(openerClassNameSelector)[0];
  if (!openerTemplate) {
    return;
  }
  _initialiseNodes(treeNavigation, openerTemplate, classBlock, classModifiers, treeNavigation.getAttribute("data-children-api-link"));
  treeNavigation.setAttribute('data-init', 1);
};
var isNodeOpened = function isNodeOpened(node) {
  return node.getAttribute('data-opened') === '1';
};
var _initialiseNodes = function initialiseNodes(context, openerTemplate, classBlock, classModifiers, childrenApiLink) {
  var nodes = context.getElementsByClassName(createSelectorClassName(classBlock, 'node', 'hasChildren'));
  var _loop = function _loop() {
    var node = nodes[i];
    var opener = appendOpenerToNode(node, openerTemplate);
    opener.onclick = function () {
      var list = node.getElementsByTagName('ul')[0];
      var isNodeBeingOpened = !isNodeOpened(node);
      node.setAttribute('data-opened', isNodeBeingOpened ? '1' : '0');
      toggleOpener(opener);
      if (list) {
        list.style.display = isNodeBeingOpened ? 'block' : 'none';
      } else {
        opener.disabled = true;
        var ajaxLoader = document.createElement('div');
        ajaxLoader.className = LOADER_CLASS_NAME + ' ' + 'ajax-loader';
        ajaxLoader.innerHTML = km_spinner.html;
        node.appendChild(ajaxLoader);
        var nodeDataset = {
          id: node.getAttribute('data-id'),
          level: node.getAttribute('data-level'),
          vehicleType: node.getAttribute('data-vehicle-type'),
          linkingTargetId: node.getAttribute('data-linking-target-id'),
          linkingTargetType: node.getAttribute('data-linking-target-type')
        };
        fetchChildren(childrenApiLink, nodeDataset, function (childrenData) {
          node.removeChild(ajaxLoader);
          if (childrenData.length === 0) {
            return;
          }
          var level = parseInt(nodeDataset.level) + 1;
          list = document.createElement('ul');
          list.className = createClassName(classBlock, classModifiers, 'list', level);
          var _iterator = _createForOfIteratorHelper(childrenData),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var childData = _step.value;
              var hasChildren = childData['hasChildren'];
              var childNode = document.createElement('li');
              childNode.className = createClassName(classBlock, classModifiers, 'node', level, hasChildren);
              childNode.setAttribute('data-id', childData.id);
              childNode.setAttribute('data-level', level.toString());
              if (nodeDataset.linkingTargetId !== null) {
                childNode.setAttribute('data-linking-target-id', nodeDataset.linkingTargetId);
              }
              if (nodeDataset.linkingTargetType !== null) {
                childNode.setAttribute('data-linking-target-type', nodeDataset.linkingTargetType);
              }
              if (nodeDataset.vehicleType !== null) {
                childNode.setAttribute("data-vehicle-type", nodeDataset.vehicleType);
              }
              var childNodeAnchor = document.createElement('a');
              childNodeAnchor.href = childData.url;
              childNodeAnchor.className = createClassName(classBlock, classModifiers, 'link', level, hasChildren);
              childNode.appendChild(childNodeAnchor);
              var childNodeName = document.createElement('span');
              childNodeName.className = createClassName(classBlock, classModifiers, 'name', level, hasChildren);
              childNodeName.innerText = childData.name;
              childNodeAnchor.appendChild(childNodeName);
              if (childData.tagName) {
                var childNodeTag = document.createElement('span');
                childNodeTag.className = createClassName(classBlock, classModifiers, 'tag', level, hasChildren);
                childNodeTag.className += ' tag --category';
                childNodeTag.innerText = childData.tagName;
                childNodeAnchor.appendChild(childNodeTag);
              }
              list.appendChild(childNode);
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
          node.appendChild(list);
          list.style.display = 'block';
          opener.disabled = false;
          _initialiseNodes(list, openerTemplate, classBlock, classModifiers, childrenApiLink);
          if (window.hasOwnProperty('onChildCategoriesInitialised')) {
            window['onChildCategoriesInitialised'](list);
          }
          if (window.hasOwnProperty('updateStickySidebar')) {
            window['updateStickySidebar']();
          }
        });
      }
    };
  };
  for (var i = 0; i < nodes.length; i++) {
    _loop();
  }
};
var createSelectorClassName = function createSelectorClassName(blockName, elementName, modifierName) {
  return blockName + (elementName ? "__".concat(elementName) : '') + (modifierName ? "--".concat(modifierName) : '');
};
var createClassName = function createClassName(classBlock, classModifiers, elementName, level, hasChildren) {
  var classBase = "".concat(classBlock, "__").concat(elementName);
  var className = "".concat(classBase);
  className += " ".concat(elementName); // BC

  for (var i = 0; i < classModifiers.length; i++) {
    className += " ".concat(classBase, "--") + classModifiers[i];
  }
  if (level) {
    className += " ".concat(classBase, "--").concat(level.toString());
    className += " ".concat(elementName, "-").concat(level.toString()); // BC
  }
  if (hasChildren) {
    className += " ".concat(classBase, "--hasChilds");
    className += " ".concat(classBase, "--hasChildren");
  }
  return className;
};
var appendOpenerToNode = function appendOpenerToNode(node, openerTemplate) {
  var openerContent = document.importNode(openerTemplate.content, true);
  node.insertBefore(openerContent, node.firstChild);
  var opener = node.getElementsByTagName('button')[0];
  var toHideChildrenIndex = isNodeOpened(node) ? 0 : 1;
  $(opener.children[toHideChildrenIndex]).hide();
  node.appendChild(opener);
  return opener;
};
var toggleOpener = function toggleOpener(opener) {
  $(opener.children[0]).toggle();
  $(opener.children[1]).toggle();
};
var fetchChildren = function fetchChildren(childrenApiLink, dataset, doOnSuccess) {
  var params = null;
  if (childrenApiLink.includes("_parentId_")) {
    childrenApiLink = childrenApiLink.replace("_parentId_", dataset.id);
  } else {
    params = _objectSpread({}, dataset);
    for (var param in params) {
      if (params.hasOwnProperty(param)) {
        if (params[param] === null) {
          delete params[param];
        }
      }
    }
    params = _objectSpread({}, params);
  }
  jQuery.get(childrenApiLink, params).done(function (data) {
    doOnSuccess(data);
  }).error(function (error) {
    console.error(error);
  });
};