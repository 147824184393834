"use strict";

onDOMReady(function () {
  var _window$dataLayer$;
  var payForm = document.getElementById('frm-payment-payForm');
  if (!payForm) {
    return;
  }

  /**
   * It means it's isFirstView.
   * @see GoogleTagManagerSubscriber.php
   * @see GoogleEcommercePageType.php
   */
  var isPurchasePage = ((_window$dataLayer$ = window.dataLayer[0]) === null || _window$dataLayer$ === void 0 ? void 0 : _window$dataLayer$.ecommercePageType) === 'purchase';
  if (!isPurchasePage) {
    return;
  }
  var buttons = document.getElementsByClassName('kvikymart-shopping-cart-submit');
  if (!buttons.length) {
    return;
  }
  var ajaxLoader = document.createElement('div');
  // noinspection InnerHTMLJS
  ajaxLoader.innerHTML = km_spinner.html;
  ajaxLoader.style.marginTop = '16px';
  var submitButton = buttons[0];
  submitButton.parentElement.append(ajaxLoader);
  payForm.addEventListener("submit", function () {
    submitButton.setAttribute("disabled", "true");
  });
  var timeoutId = setTimeout(function () {
    submitButton.click();
  }, 1500);
  document.addEventListener('keydown', function (event) {
    if (event.key === 'Escape' && timeoutId !== null) {
      clearTimeout(timeoutId);
      timeoutId = null;
      ajaxLoader.remove();
      event.preventDefault(); // Prevent Safari from exiting full-screen.
    }
  });
});