"use strict";

(function () {
  window.km_antiSpamInputAddElementById = function (id) {
    var input = document.getElementById(id);
    antiSpamInputProcess(input);
  };
  var antiSpamInputProcess = function antiSpamInputProcess(input) {
    input.value = input.getAttribute('data-anti-spam-token');

    //const tr = input.parentElement.parentElement.parentElement;
    //tr.style.display = 'none';
  };
  onDOMReady(function () {
    var elements = document.getElementsByClassName('antiSpamInput');
    for (var i = 0; i < elements.length; i++) {
      antiSpamInputProcess(elements[i]);
    }
  });
})();

// jQuery free