"use strict";

// uses jQuery
(function (window, factory) {
  window.KM = window.KM || {};
  factory(window.KM, window.jQuery);
})(window, function (KM, $) {
  'use strict';

  var findAddressForm = function findAddressForm() {
    var $form = $('#km-deliveryPaymentAddressControl-addressForm');
    return $form.length ? $form : null;
  };
  var initBillingDetailsFilledInCheckbox = function initBillingDetailsFilledInCheckbox($form) {
    var $checkbox = $('#frm-deliveryPaymentAddress-addressForm-billingDetailsFilledIn', $form);
    var autocompleteValuesByIndex = {};
    var $tbody = $checkbox.closest('tbody');
    var $tr = $checkbox.closest('tr');
    var $elements = $('tr', $tbody).not($tr);
    $.each($elements, function (rowIndex, element) {
      $('input', element).each(function (index, input) {
        autocompleteValuesByIndex[rowIndex] = input.getAttribute('autocomplete');
      });
    });
    $checkbox.unbind('change').change(function () {
      if ($checkbox.is(':checked')) {
        $elements.show();
        $.each($elements, function (rowIndex, element) {
          $('input', element).each(function (index, input) {
            input.setAttribute('autocomplete', autocompleteValuesByIndex[rowIndex]);
            input.removeAttribute('readonly');
          });
        });
      } else {
        $.each($elements, function (rowIndex, element) {
          element.setAttribute('style', 'display:none !important');
          $('input', element).each(function (index, input) {
            input.setAttribute('autocomplete', 'off');
            input.setAttribute('readonly', '');
          });
        });
      }
    }).triggerHandler('change');
  };
  var initDeliveryDetailsSameAsBillingCheckbox = function initDeliveryDetailsSameAsBillingCheckbox($form) {
    var $checkbox = $('#frm-deliveryPaymentAddress-addressForm-deliveryDetailsSameAsBilling', $form);
    $checkbox.unbind('change').change(function () {
      var $tbody = $checkbox.closest('tbody');
      var $tr = $checkbox.closest('tr');
      var $elements = $('tr', $tbody).not($tr);
      if (!$checkbox.is(':checked')) {
        $elements.show();
      } else {
        $.each($elements, function (index, element) {
          element.setAttribute('style', 'display:none !important');
        });
      }
    }).triggerHandler('change');
  };
  var initDynamicPlaceholders = function initDynamicPlaceholders($form) {
    var valToPlaceholder = {
      '#frm-deliveryPaymentAddress-addressForm-contactPersonName-givenName': '#frm-deliveryPaymentAddress-addressForm-deliveryPersonName-givenName',
      '#frm-deliveryPaymentAddress-addressForm-contactPersonName-familyName': '#frm-deliveryPaymentAddress-addressForm-deliveryPersonName-familyName',
      '#tel-national': '#frm-deliveryPaymentAddress-addressForm-deliveryPhoneNumber',
      '#email': '#frm-deliveryPaymentAddress-addressForm-deliveryEmailAddress'
    };
    jQuery.each(valToPlaceholder, function (from, to) {
      var $from = $(from, $form);
      var $to = $(to, $form);
      $from.on('change keyup', function () {
        $to.attr('placeholder', $from.val());
      });
      $from.triggerHandler('change');
    });

    // Contact PhoneNumber-CountryPrefix > Delivery PhoneNumber-CountryPrefix
    var countryPrefixFrom = $('#tel-national-countryPrefix', $form);
    var countryPrefixTo = $('#frm-deliveryPaymentAddress-addressForm-deliveryPhoneNumber-countryPrefix', $form);
    countryPrefixFrom.on('change', function () {
      countryPrefixTo.val(countryPrefixFrom.val());
    });
    countryPrefixFrom.triggerHandler('change');
  };
  var initCheckboxForFieldset = function initCheckboxForFieldset($form) {
    var checkbox = $('[data-is-for-fieldset="1"]', $form);
    if (!checkbox.length) {
      return; // No checkbox for fieldset found
    }
    var areInputValuesEmpty = function areInputValuesEmpty(inputs) {
      return inputs.toArray().every(function (input) {
        return !$(input).val();
      });
    };
    var displayLabel = function displayLabel(label, display) {
      label.css({
        opacity: display ? 1 : 0,
        top: display ? 0 : '-8px',
        pointerEvents: display ? 'all' : 'none'
      });
    };
    var label = checkbox.parent();
    var fieldset = checkbox.closest('fieldset');
    var inputs = fieldset.find('input, textarea, select').not('[data-is-for-fieldset]'); // Exclude checkbox for fieldset itself

    if (!inputs.length) {
      return; // No inputs found
    }

    // If all inputs are empty, hide checkbox otherwise check it
    if (areInputValuesEmpty(inputs)) {
      displayLabel(label, false);
    } else {
      checkbox.prop('checked', true);
    }
    label.css('transition', '125ms all linear'); // Enable animation

    // If all inputs are empty, change checkbox state and visibility accordingly
    inputs.on('keyup', function () {
      if (!areInputValuesEmpty(inputs)) {
        displayLabel(label, true);
        checkbox.prop('checked', true);
      } else {
        displayLabel(label, false);
        checkbox.prop('checked', false);
      }
    });

    // If checkbox is checked, enable inputs otherwise disable them
    checkbox.on('change', function () {
      inputs.prop('disabled', !checkbox.is(':checked'));
      inputs.css('text-decoration', !checkbox.is(':checked') ? 'line-through' : 'none');
    });

    // Page load/reload with cached data
    checkbox.triggerHandler('change');
    if (areInputValuesEmpty(inputs)) {
      if (!checkbox.is(':checked')) {
        inputs.prop('disabled', false);
        inputs.css('text-decoration', 'none');
      }
    } else {
      if (!checkbox.is(':checked')) {
        inputs.prop('disabled', true);
        inputs.css('text-decoration', 'line-through');
      }
    }
  };
  document.addEventListener('KMDeliveryPaymentAddressControl.addressFormLoaded', function (event) {
    var $form = findAddressForm();
    if ($form !== null) {
      initDeliveryDetailsSameAsBillingCheckbox($form);
      initBillingDetailsFilledInCheckbox($form);
      initDynamicPlaceholders($form);
      initCheckboxForFieldset($form);
    }
  });
  $(window).on('pageshow', function () {
    var $form = findAddressForm();
    if ($form !== null) {
      initDeliveryDetailsSameAsBillingCheckbox($form);
      initBillingDetailsFilledInCheckbox($form);
      initDynamicPlaceholders($form);
      initCheckboxForFieldset($form);
    }
  });
});